import type { FunctionComponent } from 'react';
import styles from '~/styles/error/error.module.css';
import { Breadcrumb } from '~/components/shared';
import { useRouteError } from '@remix-run/react';
import * as CSentry from '@sentry/cloudflare';

const blockClass = 'error';

export const StandardError: FunctionComponent = () => {
  const error = useRouteError();
  CSentry.captureException(error);
  return (
    <>
      <Breadcrumb text='エラーが発生しました' />
      <h1 className='headline1'>エラーが発生しました</h1>
      <div className={styles[`${blockClass}`]}>
        <p>
          ただいまサーバーに問題が生じており、ページを表示できません。この不便をおかけして大変申し訳ございません。
          <br />
          早急に問題の解決に取り組んでおります。しばらくの間お待ちいただけますと幸いです。
          <br />
        </p>
        <div className={styles[`${blockClass}__img`]}>
          <img src={'/images/pictures/error_img.png'} alt='arrow-right' />
        </div>
      </div>
    </>);

};