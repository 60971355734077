import type { FunctionComponent } from 'react';
import styles from '~/styles/error/error.module.css';
import { Link } from '@remix-run/react';
import { Breadcrumb } from '~/components/shared';

const blockClass = 'error';

export const NotFound: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='404 NOT FOUND' />
      <h1 className='headline1'>404 NOT FOUND</h1>
      <div className={styles[`${blockClass}`]}>
        <p>
          申し訳ありません。
          <br />
          お客様がお探しのページが見つかりませんでした。
          <br />
          削除されたか、入力したURLが間違っている可能性があります。
        </p>
        <p className={styles[`${blockClass}__button`]}>
          <Link to='/' className='c-button is-primary'>
            TOPに戻る
          </Link>
        </p>

        <div className={styles[`${blockClass}__img`]}>
          <img src={'/images/pictures/error_img.png'} alt='arrow-right' />
        </div>
      </div>
    </>);

};